import * as React from "react"
import Hero from "../containers/Hero";
import Features from "../containers/Features";
import Footer from "../containers/Footer";
// import Notification from "../containers/Notifications";
// import { LinkPreview } from '@dhaiwat10/react-link-preview';
// import { ReactTinyLink } from 'react-tiny-link'

export default function IndexPage() {
    return (
        <div className="">
            <Hero/>
            <Features/>
            <Footer/>
        </div>

    )
}
