/* This example requires Tailwind CSS v2.0+ */
import * as React from "react"
import {Fragment, useEffect, useState} from "react"
import {Popover, Transition} from '@headlessui/react'
import {MenuIcon, XIcon} from '@heroicons/react/outline'
import axios from "axios";
import Underline from "../components/Underline";
import Modal from "../components/Modal";
import CreatedLinks from "./CreatedLinks";
import * as containerStyles from "./container.module.css"

const navigation = [
    {name: 'Github', href: '#'},
    {name: 'Contact', href: '#'},
]


export default function Example() {
    const [url, setUrl] = useState("");
    const [shortCode, setShortCode] = useState("");
    const [shortCodeModal, setShortCodeModal] =  useState("");
    let [isOpen, setIsOpen] = useState(false);
    let [isAliasTaken, setAliasTaken] = useState(false);

    const [links, setLinks] = useState([]);

    useEffect(() => {
        const b = JSON.parse(localStorage.getItem("createLinks"))
        if (b)
            setLinks(b)
    }, []);

    useEffect(() => {
        if (shortCode.length === 0) {
            setAliasTaken(false);
            return
        }

        const delayDebounceFn = setTimeout(() => {
            console.log(shortCode)
            axios.get(`https://imp.run/is-sc-available/${shortCode}`)
                .then(function (response) {
                    console.log(response);
                    setAliasTaken(false);
                })
                .catch(function (error) {
                    setAliasTaken(true);
                });
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [shortCode])

    const generateCode = () => {
        axios.post('https://imp.run/insert', {
            'url': url,
            'short-code': shortCode
        })
            .then(function (response) {
                console.log(response);
                setIsOpen(true)
                fetchMetadataAndAddtoLS(shortCode, url)
                setShortCodeModal(shortCode)
                setShortCode("")
                setUrl("")
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const fetchMetadataAndAddtoLS = (sc, url)=>{
        axios.get(`https://metadata.imp.run/metadata/${sc}`)
            .then(function (res){
                const d = {
                    title: res.data.title,
                    favicon: res.data.icon,
                    fullUrl: url,
                    shortCode: sc,
                }
                setLinks([d, ...links])
                localStorage.setItem("createLinks", JSON.stringify([d, ...links]))
            })
    }


    return (
        <div className="relative bg-gray-50 overflow-hidden">
            <div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full" aria-hidden="true">
                <div className="relative h-full max-w-7xl mx-auto">
                    <svg
                        className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
                        width={404}
                        height={380}
                        fill="none"
                        viewBox="0 0 404 380"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={380} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"/>
                    </svg>
                    <svg
                        className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
                        width={404}
                        height={784}
                        fill="none"
                        viewBox="0 0 404 784"
                    >
                        <defs>
                            <pattern
                                id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                            </pattern>
                        </defs>
                        <rect width={404} height={784} fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"/>
                    </svg>
                </div>
            </div>

            <div className="relative pt-6 pb-16 sm:pb-24">
                <Popover>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6">
                        <nav className="relative flex items-center justify-between sm:h-10 md:justify-center"
                             aria-label="Global">
                            <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                                <div className="flex items-center justify-between w-full md:w-auto">
                                    <a href="https://imp.run">
                                        <span className="sr-only">Workflow</span>
                                        <img className="h-8 w-auto sm:h-10"
                                             src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt=""/>
                                    </a>
                                    <div className="-mr-2 flex items-center md:hidden">
                                        <Popover.Button
                                            className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">Open main menu</span>
                                            <MenuIcon className="h-6 w-6" aria-hidden="true"/>
                                        </Popover.Button>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:flex md:space-x-10">
                                {navigation.map((item) => (
                                    <a key={item.name} href={item.href}
                                       className="font-medium text-gray-500 hover:text-gray-900">
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            {/*            <div*/}
                            {/*                className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">*/}
                            {/*<span className="inline-flex rounded-md shadow">*/}
                            {/*  <a*/}
                            {/*      href="#"*/}
                            {/*      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50"*/}
                            {/*  >*/}
                            {/*    Log in*/}
                            {/*  </a>*/}
                            {/*</span>*/}
                            {/*            </div>*/}
                        </nav>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-150 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                        >
                            <div
                                className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="px-5 pt-4 flex items-center justify-between">
                                    <div>
                                        <img
                                            className="h-8 w-auto"
                                            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="-mr-2">
                                        <Popover.Button
                                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">Close menu</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true"/>
                                        </Popover.Button>
                                    </div>
                                </div>
                                <div className="px-2 pt-2 pb-3">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                {/*<a*/}
                                {/*    href="#"*/}
                                {/*    className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100"*/}
                                {/*>*/}
                                {/*    Log in*/}
                                {/*</a>*/}
                            </div>
                        </Popover.Panel>
                    </Transition>
                </Popover>

                <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
                    <div className="text-center">
                        <h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl md:text-6xl blur-none">
                            <span className="block xl:inline">Create an
                                {' '} <Underline>
                                    Alias
                                </Underline> of </span>{' '}
                            <span
                                className="block xl:inline">
                                <Underline delay={600}>
                                Important Links
                            </Underline></span>

                        </h1>
                        {/*<p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">*/}
                        {/*    A productivity booster, 10 times faster than a normal Url Shortener.*/}
                        {/*</p>*/}
                        <div
                            className="relative rounded-3xl overflow-hidden p-5">
                            <div className="relative drop-shadow-md ">
                                <form action="#" className="mt-20 lg:flex flex-row items-center justify-center lg:gap-4 gap-y-4" onSubmit={(event) => {
                                    generateCode()
                                    event.preventDefault()
                                }}>
                                    <div className="min-w-0 basis-1/2">
                                        <label htmlFor="url" className="sr-only">
                                            URL
                                        </label>
                                        <input
                                            id="url"
                                            type="url"
                                            className="block w-full border border-transparent rounded-md px-5 py-3 text-lg text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                                            placeholder="Enter your URL"
                                            value={url}
                                            onChange={(e) => setUrl(e.target.value)}
                                        />
                                    </div>
                                    <div className="min-w-0 mt-4 lg:mt-0 basis-1/4">
                                        <label htmlFor="alias" className="sr-only">
                                            Alias
                                        </label>
                                        <input
                                            id="alias"
                                            type="text"
                                            className="block w-full border border-transparent rounded-md px-5 py-3 text-lg text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                                            placeholder="Enter Alias"
                                            value={shortCode}
                                            onChange={(e) => setShortCode(e.target.value)}
                                            autoComplete='off'
                                        />
                                    </div>
                                    <div className="mt-8 lg:mt-0 basis-1/4">
                                        {
                                            isAliasTaken ?
                                                <button
                                                    className="block w-full cursor-not-allowed rounded-md border border-transparent px-5 py-3 bg-gray-900 text-base font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10"
                                                    disabled

                                                >
                                                    Generate Alias
                                                </button>
                                                :
                                                <button
                                                    type="submit"
                                                    className="block w-full rounded-md border border-transparent px-5 py-3 bg-gray-900 text-base font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10"
                                                >
                                                    Generate Alias
                                                </button>
                                        }
                                        <Modal isOpen={isOpen} setIsOpen={setIsOpen} shortCode={shortCodeModal}/>
                                    </div>
                                </form>
                            </div>
                            {/*<Alert/>*/}
                            <Transition
                                className={containerStyles.pratikInit}
                                show={isAliasTaken}
                                enter="transition-opacity ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo={containerStyles.pratikClose}
                                leave="transition-opacity duration-300"
                                leaveFrom="opacity-100"
                                leaveTo={["opacity-0", containerStyles.pratik].join(' ')}
                                unmount={false}
                            >
                                <span
                                    className="inline-flex transition-all ease-in-out mt-8 mb-100 items-center px-2.5 py-0.5 rounded-md text-md font-medium bg-pink-100 text-pink-800">  Alias is already taken , please try with a different alias
                            </span>
                            </Transition>
                        </div>
                    </div>
                    <div className="mt-20">
                        {/*<h2 className="mt-2 text-2xl text-indigo-500 tracking-tight sm:text-4xl text-center">*/}
                        {/*    Recently Created*/}
                        {/*</h2>*/}
                        {/*<h2 className="font-semibold tracking-wider text-indigo-600 text-center text-xl">Recently created</h2>*/}
                        <CreatedLinks links={links}/>
                    </div>

                </main>
            </div>
        </div>
    )
}
