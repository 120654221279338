/* This example requires Tailwind CSS v2.0+ */
import * as React from "react"

import { ChevronRightIcon, LinkIcon } from '@heroicons/react/solid'

export default function CreatedLinks({links}) {
    // const [links, setLinks] = useState([]);
    //
    // useEffect(() => {
    //     const b = JSON.parse(localStorage.getItem("createLinks"))
    //     if (b)
    //         setLinks(b)
    // }, []);
    //
    return (
        <div className="bg-white shadow-md overflow-hidden rounded-xl mt-10">
            <ul className="divide-y divide-gray-200">
                {links.map((application) => (
                    <li key={application.title}>
                        <a href={application.fullUrl} className="block hover:bg-indigo-50">
                            <div className="flex items-center px-4 py-4 sm:px-6">
                                <div className="min-w-0 flex-1 flex items-center">
                                    <div className="flex-shrink-0">
                                        <picture>
                                            <img
                                                className="h-10 w-10 rounded-full"
                                                src={application.favicon}
                                                alt=""
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src="https://www.pratikd.in/favicon-32x32.png?v=975d79a3dd30375980cf75ba847419ad";
                                                }}
                                            />
                                        </picture>

                                    </div>
                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-44">
                                        <div className="grow">
                                            <p className="text-md font-medium text-gray-800 truncate">{application.title}</p>
                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                                <LinkIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                <span className="truncate">{application.fullUrl}</span>
                                            </p>
                                        </div>
                                        <div className="md:flex hidden  items-center">
                                            <span
                                                className="flex-shrink-0 inline-block px-2 py-0.5 text-indigo-800 text-sm font-medium bg-indigo-100 rounded-full">imp.run/{application.shortCode}</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                            </div>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}
